import styled from 'styled-components/macro';

export const LoadingScreenWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 20px;
    position: absolute;
    bottom: 100px;
    font-weight: 600;
  }
`;
