import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';

export default createGlobalStyle`

${reset};

  html {
    overflow: hidden;    
    font-family: "Gill Sans", sans-serif;  
  * {
  
    box-sizing: border-box;
    padding:0;
    margin:0;
  } 
`;
