// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMintCombination(data: any) {
  const character = random_item(Object.keys(data.characters));
  const characterSkin = random_item(data.characters[character.value].skins);
  const characterFace = random_item(data.faces);
  const weapon = random_item(Object.keys(data.weapons));
  const weaponSkin = random_item(data.weapons[weapon.value].skins);
  const accessory = random_item(Object.keys(data.accessories));
  const accessorySkin = random_item(data.accessories[accessory.value].skins);
  const newCombination = [
    character.value,
    characterSkin.value,
    characterFace.value,
    weapon.value,
    weaponSkin.value,
    accessory.value,
    accessorySkin.value,
  ];

  return newCombination;
}

function random_item(items: string[]) {
  const id = Math.floor(Math.random() * items.length);
  const value = items[id];
  return { id, value };
}
