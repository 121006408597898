import styled from 'styled-components/macro';

export const SymboLogo = styled.img`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 200px;
  z-index: 2;
  cursor: pointer;
`;
