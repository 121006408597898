import { TransitionScreenWrapper } from './TransitionScreen.css';

type LoadingScreenProps = {
  transitionText: string;
  transitionImage: string;
  transitionOpacity: number;
};

export default function TransitionScreen({
  transitionText,
  transitionImage,
  transitionOpacity,
}: LoadingScreenProps) {
  return (
    <TransitionScreenWrapper $opacity={transitionOpacity}>
      <img className="lds-gif" src={`./gacha_assets/transition/${transitionImage}`} alt=""></img>
      <h1>{transitionText}</h1>
    </TransitionScreenWrapper>
  );
}
