/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef, useState } from 'react';
import type {
  AbstractMesh,
  PBRMaterial,
  Scene as SceneType,
  AnimationGroup,
} from '@babylonjs/core';
import { Vector3, Color3, Color4, MeshBuilder, Texture } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import { SkyMaterial } from '@babylonjs/materials';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';

enum MachineColors {
  RED = 'Gachapon_red.png',
  BLUE = 'Gachapon_blue.png',
  GREEN = 'Gachapon_green.png',
  PINK = 'Gachapon_pink.png',
  YELLOW = 'Gachapon_yello.png',
}

enum BallColors {
  RED = 'Ball_Red.png',
  BLUE = 'Ball_blue.png',
  GREEN = 'Ball_green.png',
  PINK = 'Ball_Pink.png',
  YELLOW = 'Ball_yellow.png',
}

type MachineSceneProps = {
  playAnimation: boolean;
  setTransition: (value: boolean) => void;
  setIsMinted: (value: boolean) => void;
  setLoader: (value: boolean) => void;
};

function MachineScene({ playAnimation, setIsMinted, setTransition, setLoader }: MachineSceneProps) {
  const [scene, setScene] = useState<SceneType>();
  const [loadMachine, setLoadMachine] = useState<boolean>(false);
  const machineAnimation = useRef<AnimationGroup>();

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.useRightHandedSystem = true;
    setupSkybox(scene);
    setScene(scene);
    scene.onReadyObservable.add(() => {
      setTimeout(() => {
        setTransition(false);
        setLoader(false);
      }, 4000);
    });
    // scene.debugLayer.show();
  };

  useEffect(() => {
    setLoadMachine(true);
  }, [scene]);

  useEffect(() => {
    if (!machineAnimation.current) return;
    if (playAnimation) machineAnimation.current.play();
    else machineAnimation.current.goToFrame(0);
  }, [playAnimation]);

  const setupSkybox = (scene: SceneType) => {
    const skybox = MeshBuilder.CreateBox('skyBox', { size: 1000.0 }, scene);
    const skyboxMaterial = new SkyMaterial('skyBox', scene);
    skyboxMaterial.luminance = 0.3;
    skyboxMaterial.inclination = 0.2;
    skyboxMaterial.azimuth = 0.2;
    skyboxMaterial.turbidity = 0.4;
    skyboxMaterial.rayleigh = 0.5;
    skyboxMaterial.backFaceCulling = false;
    skybox.material = skyboxMaterial;
  };

  const setupMachine = (machine: AbstractMesh) => {
    if (!scene) return;
    scene.stopAllAnimations();
    const machineRoot = machine.getChildMeshes()[0];
    machineRoot.scaling = new Vector3(0.015, 0.015, 0.015);
    machineAnimation.current = scene.animationGroups[0];

    machineAnimation.current.onAnimationEndObservable.add(() => {
      setTransition(true);
      setIsMinted(true);
    });
    setMachineTextures(machine);
  };

  const setMachineTextures = (machine: AbstractMesh) => {
    if (!scene) return;
    const { bannerTexture, baseTexture, ballTexture, mirrorTexture } = createMachineTextures(scene);

    const bannerMaterial = machine.getChildMeshes()[4].material as PBRMaterial;
    bannerMaterial.albedoTexture = bannerTexture;
    const machineBaseMaterial = machine.getChildMeshes()[2].material as PBRMaterial;
    machineBaseMaterial.albedoTexture = baseTexture;
    const ballmaterial = machine.getChildMeshes()[1].material as PBRMaterial;
    ballmaterial.albedoTexture = ballTexture;
    const mirrorMaterial = machine.getChildMeshes()[3].material as PBRMaterial;
    mirrorMaterial.opacityTexture = mirrorTexture;
    mirrorMaterial.transparencyMode = 2;
    mirrorMaterial.alpha = 1;
    mirrorMaterial.needDepthPrePass = true;
  };

  const createMachineTextures = (scene: SceneType) => {
    const bannerTexture = new Texture(
      './gacha_assets/textures/machine/Banner/Banner.png',
      scene,
      false,
      false,
    );

    const baseTexture = new Texture(
      `./gacha_assets/textures/machine/${MachineColors.GREEN}`,
      scene,
      false,
      false,
    );

    const ballTexture = new Texture(
      `./gacha_assets/textures/machine/Ball/${getRandomColorBall()}`,
      scene,
      false,
      false,
    );

    const mirrorTexture = new Texture(
      './gacha_assets/textures/machine/Mirror.png',
      scene,
      false,
      false,
    );

    return { bannerTexture, baseTexture, ballTexture, mirrorTexture };
  };

  const getRandomColorBall = () => {
    const ballColors = Object.values(BallColors);
    const index = Math.trunc(randomNumber(0, ballColors.length));
    const ball = ballColors[index];
    return ball;
  };

  function randomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  return (
    <Scene
      clearColor={new Color4(1, 1, 1, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 0.07, 0)}
        alpha={(2 * Math.PI) / 3 - 0.1}
        beta={Math.PI / 2}
        radius={0.4}
        lowerBetaLimit={Math.PI / 2}
        upperBetaLimit={Math.PI / 2}
        lowerRadiusLimit={0.4}
        upperRadiusLimit={0.4}
        minZ={0.01}
        panningSensibility={0}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
      />

      <Suspense fallback={<box name="box" size={0.2}></box>}>
        {loadMachine && (
          <Model
            name="base"
            sceneFilename="machine.glb"
            rootUrl="./gacha_assets/mesh/"
            onCreated={(machine: AbstractMesh) => {
              setupMachine(machine);
            }}
          ></Model>
        )}
      </Suspense>
    </Scene>
  );
}

export default MachineScene;
