import styled from 'styled-components/macro';

type TransitionScreenWrapperProps = {
  $opacity?: number;
};

export const TransitionScreenWrapper = styled.div<TransitionScreenWrapperProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => props.$opacity};
  transition: opacity 1s ease-out;

  h1 {
    position: absolute;
    margin-top: 280px;
    font-weight: 600;
    font-size: 20px;
  }

  .lds-gif {
    position: absolute;
    width: 250px;
  }
`;
