import { LoadingScreenWrapper } from './LoadingScreen.css';

export default function LoadingScreen() {
  return (
    <LoadingScreenWrapper>
      <img src={'./gacha_assets/loader/Yippy Kawaii.jpg'} alt=""></img>
      <h1>LOADING...</h1>
    </LoadingScreenWrapper>
  );
}
