import { useState } from 'react';
import { Engine } from 'react-babylonjs';
import { CanvasWrapper } from './RenderingCanvas.css';
import GachaScene from '../scenes/GachaScene';
import MachineScene from '../scenes/MachineScene';
import CharacterData from '../models/characterData.json';
import { getMintCombination } from '../utils/getMintCombination';
import MintButton from '../components/MintButton';
import ReturnButton from '../components/ReturnButton';
import TransitionScreen from '../components/TransitionScreen';
import LoadingScreen from '../components/LoadingScreen';
import { SymboLogo } from '../components/SymboLogo.css';

export default function RenderingCanvas() {
  const [combination, setCombination] = useState<string[]>(getMintCombination(CharacterData));
  const [isMinted, setIsMinted] = useState<boolean>(false);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState<boolean>(false);
  const [displayTransition, setDisplayTransition] = useState<boolean>(true);
  const [transitionText, setTransitionText] = useState<string>('Loading...');
  const [transitionImage, setTransitionImage] = useState<string>("Can't_wait.gif");
  const [transitionOpacity, setTransitionOpacity] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(true);

  const handleMintClick = () => {
    setCombination(getMintCombination(CharacterData));
    setTransitionText("Can't wait !!");
    setTransitionImage("Can't_wait.gif");
    setTimeout(() => {
      setIsAnimationPlaying(true);
    }, 500);
  };

  const handleReturnClick = () => {
    setTransitionOpacity(1);
    setTransitionText('Why not me ??');
    setTransitionImage('Why_not_me.gif');
    setDisplayTransition(true);
    setIsAnimationPlaying(false);
    setIsMinted(false);
  };

  const handleLogoClick = () => {
    window.open('https://www.symbo.us/', '_blank', 'noopener,noreferrer');
  };

  return (
    <CanvasWrapper>
      {loader && <LoadingScreen />}
      <SymboLogo src={'gacha_assets/logos/symboLogo.png'} onClick={handleLogoClick} />
      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        {isMinted ? (
          <GachaScene
            combination={combination}
            setTransition={setDisplayTransition}
            setTransitionOpacity={setTransitionOpacity}
          />
        ) : (
          <MachineScene
            playAnimation={isAnimationPlaying}
            setIsMinted={setIsMinted}
            setTransition={setDisplayTransition}
            setLoader={setLoader}
          />
        )}
      </Engine>
      {!isMinted ? (
        <MintButton handleMintClick={handleMintClick} />
      ) : (
        <ReturnButton handleReturnClick={handleReturnClick} />
      )}
      {displayTransition && (
        <TransitionScreen
          transitionText={transitionText}
          transitionImage={transitionImage}
          transitionOpacity={transitionOpacity}
        />
      )}
    </CanvasWrapper>
  );
}
