import styled from 'styled-components/macro';

export const MintButtonWrapper = styled.div`
  position: absolute;
  left: calc(50% - 115px);
  bottom: 80px;
  width: 230px;
  height: 100px;
  background: url('gacha_assets/textures/Button.png') no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 800;
  cursor: pointer;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-family: 'Gill Sans', sans-serif;

  color: white;
`;
