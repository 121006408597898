/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useMemo, useState } from 'react';
import type {
  AbstractMesh,
  PBRMaterial,
  TransformNode,
  Bone,
  Scene as SceneType,
} from '@babylonjs/core';
import { Vector3, Color3, Color4, MeshBuilder, Texture } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import { SkyMaterial } from '@babylonjs/materials';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';

type GachaProps = {
  combination: string[];
  setTransition: (value: boolean) => void;
  setTransitionOpacity: (value: number) => void;
};

function GachaScene({ combination, setTransition, setTransitionOpacity }: GachaProps) {
  const [scene, setScene] = useState<SceneType>();
  const [character, setCharacter] = useState<AbstractMesh>();
  const [loadCharacter, setLoadCharacter] = useState<boolean>(false);
  const [loadWeapon, setLoadWeapon] = useState<boolean>(false);
  const [loadAccessory, setLoadAccessory] = useState<boolean>(false);

  const weaponBone = useMemo(() => {
    if (!character) return;
    const skeleton = character.getChildMeshes()[2].skeleton;
    if (!skeleton) return;
    return skeleton.bones.filter((bone: Bone) => bone.name === 'Wrist_R');
  }, [character]);

  const headBone = useMemo(() => {
    if (!character) return;
    const skeleton = character.getChildMeshes()[2].skeleton;
    if (!skeleton) return;
    return skeleton.bones.filter((bone: Bone) => bone.name === 'Head_M');
  }, [character]);

  const chestBone = useMemo(() => {
    if (!character) return;
    const skeleton = character.getChildMeshes()[2].skeleton;
    if (!skeleton) return;
    return skeleton.bones.filter((bone: Bone) => bone.name === 'Chest_M');
  }, [character]);

  useEffect(() => {
    setLoadCharacter(true);
  }, [scene]);

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.useRightHandedSystem = true;
    scene.onReadyObservable.add(() => {
      setTimeout(() => {
        setTransitionOpacity(0);
      }, 2100);
      setTimeout(() => {
        setTransition(false);
      }, 3000);
    });
    setupSkybox(scene);
    setScene(scene);
    // scene.debugLayer.show();
  };

  const setupSkybox = (scene: SceneType) => {
    const skybox = MeshBuilder.CreateBox('skyBox', { size: 1000.0 }, scene);
    const skyboxMaterial = new SkyMaterial('skyBox', scene);
    skyboxMaterial.luminance = 0.3;
    skyboxMaterial.inclination = 0.2;
    skyboxMaterial.azimuth = 0.2;
    skyboxMaterial.turbidity = 0.4;
    skyboxMaterial.rayleigh = 0.5;
    skyboxMaterial.backFaceCulling = false;
    skybox.material = skyboxMaterial;
  };

  const setupCharacter = (character: AbstractMesh) => {
    setupCharacterTextures(character);
    setCharacter(character);
    setLoadWeapon(true);
    setLoadAccessory(true);
  };

  const setupCharacterTextures = (character: AbstractMesh) => {
    const { characterBodyTexture, characterFaceTexture } = createCharacterTextures(combination);
    const characterFace = character.getChildMeshes()[1].material as PBRMaterial;
    const characterBody = character.getChildMeshes()[2].material as PBRMaterial;

    characterFace.albedoTexture = characterFaceTexture;
    characterFace.transparencyMode = 3;
    characterFace.albedoTexture.hasAlpha = true;

    characterBody.albedoTexture = characterBodyTexture;
  };

  const createCharacterTextures = (combination: string[]) => {
    const characterBodyTexture = new Texture(
      `./gacha_assets/textures/${combination[0]}/${combination[1]}.png`,
      scene,
      false,
      false,
    );
    const characterFaceTexture = new Texture(
      `./gacha_assets/textures/face/${combination[2]}.png`,
      scene,
      false,
      false,
    );

    return { characterBodyTexture, characterFaceTexture };
  };

  const setupWeapon = (weapon: AbstractMesh) => {
    setWeaponTexture(weapon);
    attachWeaponToBone(weapon);
  };

  const setWeaponTexture = (weapon: AbstractMesh) => {
    if (!scene) return;
    const weaponTexture = createWeaponTexture(scene);
    const weaponMaterial = weapon.getChildMeshes()[1].material as PBRMaterial;
    weaponMaterial.albedoTexture = weaponTexture;
  };

  const attachWeaponToBone = (weapon: AbstractMesh) => {
    const weaponRoot = weapon.getChildMeshes()[0];
    if (!weaponBone) return;
    weaponRoot.rotationQuaternion = null;

    weapon.attachToBone(weaponBone[0], character as TransformNode);
    weaponRoot.rotation.y = Math.PI;
    weaponRoot.scaling = new Vector3(500, 500, 500);
  };

  const createWeaponTexture = (scene: SceneType) => {
    const weaponTexture = new Texture(
      `./gacha_assets/textures/weapons/${combination[4]}.png`,
      scene,
      false,
      false,
    );

    return weaponTexture;
  };

  const setupAccessory = (accessory: AbstractMesh) => {
    setAccessoryTexture(accessory);
    attachAccessoryToBone(accessory);
  };

  const setAccessoryTexture = (accessory: AbstractMesh) => {
    if (!scene) return;
    const accessoryTexture = createAccessoryTexture(scene);
    const accessoryMaterial = accessory.getChildMeshes()[1].material as PBRMaterial;
    accessoryMaterial.albedoTexture = accessoryTexture;
  };

  const createAccessoryTexture = (scene: SceneType) => {
    const accessoryTexture = new Texture(
      `./gacha_assets/textures/accessories/${combination[6]}.png`,
      scene,
      false,
      false,
    );

    return accessoryTexture;
  };

  const attachAccessoryToBone = (accessory: AbstractMesh) => {
    const accessoryRoot = accessory.getChildMeshes()[0];
    const bone = getAccessoryBone(combination[5]);
    const accessoryMesh = accessory.getChildMeshes()[1];
    if (!bone) return;
    accessoryRoot.rotation.z = Math.PI / 2;
    accessoryRoot.rotation.y = Math.PI;
    setAccessoryPosition(accessoryMesh, combination[5]);
    accessory.attachToBone(bone[0], character as TransformNode);
    accessoryRoot.scaling = new Vector3(500, 500, 500);
  };

  const getAccessoryBone = (accessoryName: string) => {
    if (!chestBone || !headBone) return;
    let bone: Bone[] = [];

    switch (accessoryName) {
      case 'hat':
        bone = headBone;
        break;
      case 'glasses':
      case 'minihat':
        bone = headBone;
        break;

      case 'backpack':
        bone = chestBone;
        break;

      case 'bag':
        bone = chestBone;
        break;

      case 'scarf':
        bone = chestBone;
        break;

      default:
        break;
    }

    return bone;
  };

  const setAccessoryPosition = (mesh: AbstractMesh, accessoryName: string) => {
    switch (accessoryName) {
      case 'glassest':
      case 'minihat':
        break;
      case 'hat':
        updateMeshPositionY(mesh, -0.05);
        break;
      case 'backpack':
        updateMeshPositionY(mesh, -0.001);
        updateMeshPositionZ(mesh, 0.002);
        break;
      case 'bag':
        updateMeshPositionY(mesh, -0.037);
        break;
      case 'scarf':
        updateMeshPositionY(mesh, -0.037);
        break;

      default:
        break;
    }
  };

  // const updateMeshPositionX = (mesh: AbstractMesh, positionX: number) => {
  //   mesh.position = new Vector3(positionX, mesh.position.y, mesh.position.z);
  // };
  const updateMeshPositionY = (mesh: AbstractMesh, positionY: number) => {
    mesh.position = new Vector3(mesh.position.x, positionY, mesh.position.z);
  };
  const updateMeshPositionZ = (mesh: AbstractMesh, positionZ: number) => {
    mesh.position = new Vector3(mesh.position.x, mesh.position.y, positionZ);
  };

  return (
    <Scene
      clearColor={new Color4(1, 1, 1, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 0.07, 0)}
        alpha={Math.PI / 2}
        beta={Math.PI / 2}
        radius={0.4}
        lowerBetaLimit={Math.PI / 2}
        upperBetaLimit={Math.PI / 2}
        lowerRadiusLimit={0.4}
        upperRadiusLimit={0.4}
        minZ={0.01}
        panningSensibility={0}
      />
      <hemisphericLight
        name="hemisphericLight"
        direction={new Vector3(0, 1, 0)}
        specular={new Color3(0, 0, 0)}
      />

      <Suspense fallback={<box name="box" size={0.2}></box>}>
        {loadCharacter && (
          <Model name="base" sceneFilename="miniscene.glb" rootUrl="./gacha_assets/mesh/"></Model>
        )}
        {loadCharacter && (
          <Model
            name="character"
            sceneFilename={`${combination[0]}.glb`}
            rootUrl="./gacha_assets/mesh/characters/"
            onCreated={(character: AbstractMesh) => {
              setupCharacter(character);
            }}
          ></Model>
        )}
        {loadWeapon && (
          <Model
            name="weapon"
            sceneFilename={`${combination[3]}.glb`}
            rootUrl="./gacha_assets/mesh/weapons/"
            onCreated={(weapon: AbstractMesh) => {
              setupWeapon(weapon);
            }}
          ></Model>
        )}
        {loadAccessory && (
          <Model
            name="accessory"
            sceneFilename={`${combination[5]}.glb`}
            rootUrl="./gacha_assets/mesh/accessories/"
            onCreated={(accessory: AbstractMesh) => {
              setupAccessory(accessory);
            }}
          ></Model>
        )}
      </Suspense>
    </Scene>
  );
}

export default GachaScene;
